.week-interval {
  width: 100%;
  color: #1b1c21;
  font-size: 4.8vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #25262b;
  padding: 6.3vw;
  margin-bottom: 1.33vw;
  border-radius: 8vw;

  transition: color 0.3s ease;
}

.week-interval__time {
  transition: color 0.3s ease;
  .week-interval.week-interval.working & {
    color: #fff;
  }

  & input {
    display: inline-block;
    margin: 0;
    padding: 0;
    background-color: transparent;
    border: none;
    color: inherit;
    font-size: inherit;
    width: 5ch;
  }
  & input[type='time']::-webkit-calendar-picker-indicator {
    background: none;
    display: none;
  }
}

.week-interval__select {
  height: 0;
  opacity: 0;
  width: 0;

  @media (min-width: 1000px) {
    &:focus {
      opacity: 1;
      height: auto;
      width: auto;
    }
  }
}

.week-interval__select-label {
  flex: 1;
  text-align: center;
  margin: 0 2ch 0 1ch;
  font-weight: 500;
  font-size: 3.2vw;
  transition: color 0.3s ease;

  .week-interval.working & {
    color: #aaa;
  }
}

.week-interval__checkbox {
  position: relative;
  width: 13.3vw;
  height: 7.45vw;
  background-color: #1b1c21;
  padding: 0;
  margin: 0;
  border: none;
  border-radius: 4.7vw;
  transition: background-color 0.3s ease;

  &::after {
    position: absolute;
    top: 50%;
    transform: translate(1vw, -50%);
    content: '';
    display: block;
    width: 5.45vw;
    height: 5.45vw;
    background-color: #25262b;
    border-radius: 50%;
    transition: transform 0.3s ease;
  }

  .week-interval.working & {
    background-color: #fff;
  }

  .week-interval.working &::after {
    transform: translate(6.88vw, -50%);
  }
}
