.tall-number-input {
  color: #fff;
  font-size: 20vw;
  text-align: center;
  font-weight: 400;

  &.small {
    font-size: 9.6vw;
    flex-basis: 50%;
  }
}

.tall-number-input__title {
  font-size: 0.32em;
  color: #aaa;
  margin-bottom: 0.07em;
}

.tall-number-input__input {
  width: 4ch;
  margin: 0;
  padding: 0;
  text-align: center;
  background: none;
  font-size: inherit;
  color: inherit;
  background-color: transparent;
  border: none;
  margin-bottom: 0.2em;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.tall-number-input__controls {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: inherit;

  & button {
    position: relative;
    font-size: inherit;
    color: #aaa;
    background-color: #25262b;
    border: none;
    padding: 0.3em 0.45em;
    display: flex;
    justify-content: center;

    &::after {
      content: '';
      display: block;
      position: absolute;
      height: 70%;
      width: 0.02em;
      background-color: #404040;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }

    & svg {
      margin: auto;
      width: 0.52em;
      stroke: #aaa;
      line-height: 1;
    }

    &:first-child {
      border-radius: 0.4em 0 0 0.4em;
    }
    &:last-child {
      border-radius: 0 0.4em 0.4em 0;
      &::after {
        display: none;
      }
    }
  }
}
