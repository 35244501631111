@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

html {
  background-color: #1b1c21;
  min-height: 100dvh;
}
body {
  background-color: #1b1c21;
  font-family: 'Ubuntu', sans-serif;
  min-height: 100dvh;
}

#root {
  min-height: 100dvh;
}

.App {
  margin: auto;
  padding-top: 15vw;
  width: 95vw;
  min-height: 100dvh;

  display: flex;
  flex-flow: column;
}
