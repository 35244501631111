.bottom-menu__item {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10vw;
  border-radius: 4.133vw;
  padding: 1vw 3.5vw;
  color: #000;
  background-color: transparent;
  border: none;

  &:last-child {
    margin-right: 0;
  }

  &.active {
    background-color: #fff;
  }

  &.active > .bottom-menu__svg {
    fill: #000;
  }

  &.active > .bottom-menu__title {
    display: block;
  }
}

.bottom-menu__svg {
  fill: rgba(170, 170, 170, 0.67);
  width: 6.788vw;
}

.bottom-menu__title {
  display: none;
  padding-left: 1.5vw;
  font-size: 3.2vw;
  font-weight: 500;
}
