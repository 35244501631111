.bottom-menu {
  position: fixed;
  z-index: 999;
  bottom: 5vw;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: auto;
  background-color: #2f3035;
  border-radius: 9.733vw;
  padding: 3vw;
}
