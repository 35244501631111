.number-input {
  font-size: 4.8vw;
  color: #fff;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  & button {
    position: relative;
    font-size: inherit;
    color: #aaa;
    background-color: #2f3035;
    border: none;
    height: 1.5em;
    padding: 0.4em;
    display: flex;
    justify-content: center;

    & svg {
      margin: auto;
      width: 0.8em;
      stroke: #aaa;
      line-height: 1;
    }

    &:first-of-type {
      border-radius: 0.4em 0 0 0.4em;
    }
    &:last-of-type {
      border-radius: 0 0.4em 0.4em 0;
      &::after {
        display: none;
      }
    }
  }
}

.number-input__title {
  text-align: center;
  width: 100%;
  margin-bottom: 0.36em;
  color: #aaa;
  font-size: 0.6em;
}

.number-input__container {
  display: block;
  height: 1.5em;
  width: 5.55em;
  background-color: #25262b;
  border: none;
  text-align: center;
}

.number-input__input {
  width: 4ch;
  margin: 0;
  margin-top: 0.2em;
  margin-bottom: 0.2em;
  padding: 0;
  border: none;
  background-color: transparent;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
}
