.settings__container {
  min-height: 100%;
  width: 100%;
  margin: auto;
  margin-top: 8vw;
  margin-bottom: 25vw;
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-content: center;

  & > * {
    margin-bottom: 5vw;
  }
}

.width100 {
  width: 100%;
  margin: 0;
}
