.settings-empty {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  flex-grow: 0.7;

  margin-left: auto;
  margin-right: auto;
  width: 70vw;
  color: #2f3035;
  font-size: 9.6vw;
  font-weight: 400;
  text-align: center;
}
