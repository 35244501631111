.button {
  color: #000;
  font-size: 4.27vw;
  border: none;
  border-radius: 0.875em;
  padding: 0.25em 0.95em;
  background-color: #fff;

  &:hover {
    cursor: pointer;
  }
}
