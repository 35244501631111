.header-bar {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  background-color: #1b1c21;
  color: #fff;
  font-size: 4.3vw;
  padding: 3.7vw 5.7vw;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  text-align: center;
  z-index: 999;

  & * {
    margin: auto;
  }

  & *:first-child {
    margin-left: 0;
  }
  & *:last-child {
    margin-right: 0;
  }
}
