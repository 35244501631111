.stat-item {
  text-align: center;
  background-color: #25262b;
  border-radius: 8vw;
  padding: 5vw 0;

  &__value {
    font-size: 9.6vw;
    font-weight: 500;
    color: #fff;
    margin-bottom: 1.5vw;
  }
  &__title {
    font-size: 3.2vw;
    font-weight: 400;
    color: #aaa;
  }
}
